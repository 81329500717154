<template>
  <v-container fluid>
    <v-row justify="center" no-gutters class="mt-3 pb-4 scheduleSelection">
      <v-hover v-slot:default="{ hover }">
        <v-btn
          icon
          :elevation="hover ? 2 : 0"
          :color="hover ? 'teal' : 'grey'"
          @click="getFacilitySchedule"
        >
          <v-icon>mdi-refresh-circle</v-icon>
        </v-btn>
      </v-hover>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-col
        md="2"
        justify="center"
        v-if="
          checkReadPermission($modules.facility.schedule.slug) &&
          checkReadPermission($modules.events.schedule.slug)
        "
      >
        <router-link :to="`/schedule`">
          <v-btn block color="#062b48" dark tile>Facility</v-btn>
        </router-link>
      </v-col>
      <v-col
        md="2"
        justify="center"
        v-if="
          checkReadPermission($modules.facility.schedule.slug) &&
          checkReadPermission($modules.events.schedule.slug)
        "
      >
        <router-link :to="`/event-schedule`">
          <v-btn block light tile>Events</v-btn>
        </router-link>
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="2" class="text-lg-right mr-2">
        <v-select
          v-if="
            checkReadPermission($modules.facility.schedule.slug) &&
            checkReadPermission($modules.events.schedule.slug)
          "
          :items="venueServices"
          v-model="venueService"
          item-value="venue_service_id"
          item-text="name"
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          return-object
          background-color="#fff"
          dense
          @change="initializeScheduleForVenueService()"
        ></v-select>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-3 pb-4">
      <v-col sm="2">
        <router-link :to="`/golf-schedule`">
          <v-btn color="#062b48" dark tile>Day</v-btn>
        </router-link>
        <v-btn @click="gotoCalendar" light tile>Month</v-btn>
      </v-col>
      <v-spacer></v-spacer>

<!--      <v-spacer></v-spacer>-->
<!--      <v-col sm="4">-->
<!--        <v-row no-gutters>-->
<!--          <v-col sm="1" class="text-lg-center mr-1">-->
<!--            <v-btn-->
<!--              :disabled="!venueService.venue_service_id"-->
<!--              fab-->
<!--              dark-->
<!--              x-small-->
<!--              color="teal"-->
<!--              @click="prevDate"-->
<!--            >-->
<!--              <v-icon dark>mdi-menu-left</v-icon>-->
<!--            </v-btn>-->
<!--          </v-col>-->
<!--          <v-col sm="8" class="text-lg-center">-->
<!--            <date-field-->
<!--              v-model="date"-->
<!--              :button="true"-->
<!--              :dayName="true"-->
<!--              :disabled="!venueService.venue_service_id"-->
<!--              @change="getFacilitySchedule"-->
<!--            >-->
<!--            </date-field>-->
<!--          </v-col>-->
<!--          <v-col sm="1" class="text-lg-center ml-1">-->
<!--            <v-btn-->
<!--              fab-->
<!--              dark-->
<!--              x-small-->
<!--              color="teal"-->
<!--              :disabled="!venueService.venue_service_id"-->
<!--              @click="nextDate"-->
<!--            >-->
<!--              <v-icon dark>mdi-menu-right</v-icon>-->
<!--            </v-btn>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--      </v-col>-->

      <v-col sm="4" class="text-lg-right">
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-btn
              v-if="facilities.length > 0"
              @click="reportDownload"
              color="teal white--text"
              light
              tile
              class="mr-2"
          >Report</v-btn
          >
          <v-btn color="#062b48" dark tile @click="toDay" class="mr-2">TODAY</v-btn>
          <v-btn
            color="#062b41"
            dark
            tile
            @click="scrollToAvailable"
            class="mr-2"
            >Quick Scroll
          </v-btn>

          <v-col
            v-if="
              checkReadPermission($modules.facility.schedule.slug) &&
              !checkReadPermission($modules.events.schedule.slug)
            "
            sm="5"
            class="text-lg-right mr-2"
          >
            <v-select
              :items="venueServices"
              v-model="venueService"
              item-value="venue_service_id"
              item-text="name"
              outlined
              background-color="#fff"
              dense
              :menu-props="{ bottom: true, offsetY: true }"
              return-object
              @change="initializeScheduleForVenueService()"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="d-flex flex-nowrap justify-start">
      <v-btn-toggle
          class="mb-1 full-width-toggle"
          v-model="selectedWeek"
          active-class="date-active"
          borderless
          mandatory
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn value="lastWeek" @click="prevWeek" v-bind="attrs"
                   v-on="on">
              <v-icon dark>mdi-menu-left</v-icon>
            </v-btn>
          </template>
          Previous week
        </v-tooltip>
        <v-btn :value="index" v-for="(day,index) in dateButtonArray" :key="index" @click="gotoDate(day)" class="block-dark-button">
          {{ getFormattedDate(day.date) }}
        </v-btn>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn value="nextWeek" @click="nextWeek" v-bind="attrs"
                   v-on="on" >
              <v-icon dark>mdi-menu-right</v-icon>
            </v-btn>
          </template>
          Next week
        </v-tooltip>
      </v-btn-toggle>

      <date-field
          class-name="ml-auto"
          v-model="date"
          :icon="true"
          :fullHeight="true"
          :dayName="true"
          :disabled="!venueService.venue_service_id"
          @change="getFacilitySchedule"
      >
      </date-field>

    </div>

    <div style="display: inline; display: flex">
      <div
        style="width: 200px"
        class="d-flex flex-column overflow-x-hidden overflow-y-hidden"
      >
        <golf-header-cell name="Tee Time"></golf-header-cell>
        <div
          class="overflow-auto no-scroll"
          ref="scheduleTimer"
          @scroll="onScroll"
          :style="`min-height: ${scheduleHeight}px; max-height: ${scheduleHeight}px;margin-right:-1px;`"
        >
          <golf-time-column
            :increment="increment"
            :height="height"
          ></golf-time-column>
        </div>
      </div>
      <div style="width: calc(100% - 200px)" ref="main_box">
        <div
          class="d-flex flex-row overflow-x-hidden overflow-y-hidden"
          ref="scheduleHeader"
        >
          <template v-for="facility in facilities">
            <golf-header-cell
              :key="`h_${facility.id}`"
              :increment="increment"
              :height="height"
              :name="facility.name"
              :capacity="facility.capacity"
              :formation="facility.game_formations"
              :mainBoxWidth="getMainBoxWidth()"
            ></golf-header-cell>
          </template>
        </div>
        <div
          v-resize="onResize"
          ref="schedule"
          class="d-flex overflow-auto grey--text text--lighten-2 caption"
          :style="`min-height: ${scheduleHeight}px; max-height: ${scheduleHeight}px`"
          @scroll="onScroll"
        >
          <template v-for="(facility, index) in facilities">
            <golf-facility-column
              :date="date"
              :key="`t_${facility.id}`"
              :increment="increment"
              :height="height"
              @open-booking-form="openBooking"
              @open-participans-model="openParticipants"
              :name="facility.name"
              :capacity="facility.capacity"
              :perCapacity="facility.per_capacity"
              :minBookingTime="facility.min_booking_time"
              :id="facility.id"
              @rescheduleBooking="rescheduleBooking"
              :bookings="getSlotData(index)"
            >
            </golf-facility-column>
          </template>
        </div>
      </div>
    </div>

    <!-- <golf-booking-form
      v-bind="bookingForm"
      @repeatBookingReceipt="repeatBookingReceipt"
      @close="bookingForm.showBookingForm = false"
      @booked="completeOrder"
      @cancel="getFacilitySchedule"
      @pay="openOrderCloseBooking"
      @refresh="getFacilitySchedule"
      :perCapacity="bookingForm.per_capacity"
      :minBookingTime="bookingForm.min_booking_time"
      :increment="increment"
      :bookingCount="bookingForm.bookingCount"
      @reschedule="showReschedule"
    ></golf-booking-form> -->
    <booking-form
      v-bind="bookingForm"
      @repeatBookingReceipt="repeatBookingReceipt"
      @close="bookingForm.showBookingForm = false"
      @booked="completeOrder"
      @cancel="getFacilitySchedule"
      @pay="openOrderCloseBooking"
      @refresh="getFacilitySchedule"
      :perCapacity="bookingForm.per_capacity"
      :minBookingTime="bookingForm.min_booking_time"
      :increment="increment"
      @reschedule="showReschedule"
      :bookingCount="bookingForm.bookingCount"
      @repeatRefundAndCancel="repeatRefundAndCancel"
    ></booking-form>
    <order-details
      :id="orderId"
      :ids="orderIds"
      @close="(orderId = null), (orderIds = null)"
      @paymentDone="getFacilitySchedule"
    ></order-details>
    <!-- <golf-participants
      :refresh="refresh"
      v-bind="participant"
      @open-booking="openBooking"
      @open-capacity-booking="openBookingCapacity"
      @close="(participant.showParticipants = false), getFacilitySchedule()"
      @pay="openOrderCloseBooking"
      @booked="completeOrder"
      :venue_service_id="venueService.venue_service_id"
    ></golf-participants> -->
    <participants-popup 
        :refresh="refresh"
        :venue_service_id="venueService.venue_service_id"
        v-bind="participant"
        @open-booking="openBooking"
        @open-capacity-booking="openBookingCapacity"
        @close="(participant.showParticipants = false), getFacilitySchedule()"
        @pay="openOrderCloseBooking"
        @booked="completeOrder"
    />
    <booking-details
      :venueServiceId="this.venueService.venue_service_id"
      :id="bookingOrderId"
      @close="bookingOrderId = null"
      @receipt="showReceipt"
      @repeatBookingReceipt="repeatBookingReceipt"
      @reschedule="showReschedule"
      @refund="showRefund"
      @cancel="deleteBookings"
      @cancelRepeatBooking="cancelRepeatBooking"
      @repeatRefundAndCancel="repeatRefundAndCancel"
      @refundSession="
        (participant.showParticipants = false), getFacilitySchedule()
      "
      @openCustomerProfile="openCustomerProfile"
    ></booking-details>

    <refund
      v-if="refund_dialog"
      :repeatRefundAmount="repeatRefundAmount"
      :repeatBookingdata="repeatBookingdata"
      :show="refund_dialog"
      @close="refund_dialog = false"
      @reload="getFacilitySchedule"
      @refunded="
        (refund_dialog = false), (bookingOrderId = null), completeOrder()
      "
    ></refund>
    <customer-model v-bind="userModel" @close="userModel.userID = null" />
  </v-container>
</template>

<script>
import CustomerModel from "../../../Clients/Customer/CustomerModel";
import GolfHeaderCell from "@/components/Schedule/Facility/Golf/GolfHeaderCell";
import GolfTimeColumn from "@/components/Schedule/Facility/Golf/GolfTimeColumn";
import GolfFacilityColumn from "@/components/Schedule/Facility/Golf/GolfFacilityColumn";

import OrderDetails from "@/components/Order/OrderDetails";
// import GolfParticipants from "./GolfParticipants";
import ParticipantsPopup from "../ParticipantsPopup";
// import GolfBookingForm from "./GolfBookingForm";
import BookingForm from "../BookingForm.vue";
import refund from "@/components/Order/Refund.vue";
import BookingDetails from "../BookingDetails";
import moment from "moment";
export default {
  components: {
    GolfFacilityColumn,
    GolfTimeColumn,
    GolfHeaderCell,
    BookingForm,
    // GolfBookingForm,
    OrderDetails,
    // GolfParticipants,
    ParticipantsPopup,
    BookingDetails,
    refund,
    CustomerModel,
  },
  data() {
    return {
      userModel: { userID: null, type: "details" },
      repeatRefundAmount: null,
      repeatBookingdata: null,
      drag: false,
      facilities: [],
      bookingForm: {},
      date: moment().format("YYYY-MM-DD"),
      currentDate: null,
      venueService: {},
      gameFormationFilter: [],
      increment: 60,
      height: 1200,
      perCapacity: 0,
      orderId: null,
      orderIds: null,
      bookingOrderId: null,
      rescheduleId: null,
      participant: {},
      minBookingTime: 60,
      scheduleHeight: 500,
      refund_dialog: false,
      refresh: false,
      isGolfEnabledExist: false,
      dateButtonArray: [],
      selectedWeek:0,
    };
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.showLoader("Loading");
      this.$store.dispatch("loadVenueServices").then(() => {
        this.$nextTick(() => {
          if (this.$store.getters.getSportsService.length) {
            this.getRouteParams();
            this.initializeScheduleForVenueService();
            this.generateWeekDays();
          }
          this.hideLoader();
        });
      });
    } else {
      if (this.$store.getters.getSportsService.length) {
        this.getRouteParams();
        this.initializeScheduleForVenueService();
        this.generateWeekDays();
      }
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getSportsService;
    },
    venueServiceConfiguration() {
      return this.$store.getters.getConfigurationByVenueServiceId(
        this.venueService.venue_service_id
      );
    },
  },
  methods: {
    rescheduleBooking(obj){
      let fromData = obj.from;
      let toData = obj.to;
      this.showLoader('Trying to reschedule...');
      let data = { from: fromData, to: toData };
      this.$http
          .post(`venues/facilities/bookings/golf/get-reschedule-data`, data)
          .then((res) => {
            if (res.status == 200 && res.data.status == true) {
              this.hideLoader();
              this.showSuccess("Loaded reschedule data");
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    getFormattedDate(date){
      return moment(date).format('ddd, DD MMM YY');
    },
    selectCurrentDay(){
      let date = this.date;
      setTimeout(() => {
        this.selectedWeek = this.dateButtonArray.findIndex(ele => ele.date == date);
      },200)
    },
    gotoDate(day){
      this.date = day.date;
      this.getFacilitySchedule();
    },
    prevWeek(){
      let currentDate = this.date;
      const newDate = moment(currentDate);
      this.date = newDate.subtract(7,'days').format('YYYY-MM-DD');
      this.selectedWeek = 0;
      this.generateWeekDays();
      this.selectCurrentDay();
      this.getFacilitySchedule();
    },
    nextWeek(){
      let currentDate = this.date;
      const newDate = moment(currentDate);
      this.date = newDate.add(7,'days').format('YYYY-MM-DD');
      this.selectedWeek = 0
      this.generateWeekDays();
      this.selectCurrentDay();
      this.getFacilitySchedule();
    },
    generateWeekDays() {
      this.dateButtonArray = [];
      let startDate = this.date;
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const result = [];
      const currentDate = new Date(startDate);

      // Calculate the Monday of the current week
      const currentDayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
      const mondayOffset = currentDayOfWeek - 1; // Offset to Monday
      const mondayDate = new Date(currentDate);
      mondayDate.setDate(mondayDate.getDate() - mondayOffset); // Set to Monday of the current week

      // Generate the list of weekdays starting from Monday
      for (let i = 0; i < 7; i++) {
        result.push({
          day: daysOfWeek[i],
          date: new Date(mondayDate.getFullYear(), mondayDate.getMonth(), mondayDate.getDate() + i).toISOString().split('T')[0]
        });
      }

      this.dateButtonArray = result;
    },
    initializeScheduleForVenueService() {
      if (this.venueService.venue_service_id) {
        if (
            !this.$store.getters.getConfigurationStatus(
                this.venueService.venue_service_id
            )
        ) {
          this.$store
              .dispatch(
                  "loadConfigurationsByVenueServiceId",
                  this.venueService.venue_service_id
              )
              .then((response) => {
                if (response.status == 200) {
                  if (!this.venueServiceConfiguration.is_golf_enabled) {
                    this.$router.push({
                      name: "Schedule",
                      params: {
                        data: btoa(
                            JSON.stringify({
                              venue_service: this.venueService,
                              date: this.date,
                            })
                        ),
                      },
                    });
                  } else {
                    this.getFacilitySchedule();
                  }
                }
              });
        } else {
          if (!this.venueServiceConfiguration.is_golf_enabled) {
            this.$router.push({
              name: "Schedule",
              params: {
                data: btoa(
                    JSON.stringify({
                      venue_service: this.venueService,
                      date: this.date,
                    })
                ),
              },
            });
          } else {
            this.getFacilitySchedule();
          }
        }
      }
    },
    getRouteParams() {
      if (this.$route.params.data) {
        let data = JSON.parse(atob(this.$route.params.data));
        this.venueService = data.venue_service;
        this.date = data.date;
        if (data.order_id) {
          this.bookingOrderId = data.order_id;
        }
      } else {
        this.$router.push({
          name: "Schedule",
        });
      }
    },
    onResize() {
      this.scheduleHeight = window.innerHeight - 350;
    },
    openBooking(data) {
      if (
          data.per_capacity == 0 &&
          (data.status == "paid" || data.status == "trainer")
      ) {
        this.bookingOrderId = data.order_id;
      } else {
        this.openBookingForm(data);
      }
    },
    openBookingCapacity(data) {
      if (data.status == "paid" || data.status == "trainer") {
        this.bookingOrderId = data.order_id;
      } else {
        this.openBookingForm(data);
      }
    },
    openBookingForm(data) {
      this.bookingForm = {
        showBookingForm: true,
        start_time: moment(data.start_time, "hh:mm a").format("HH:mm:ss"),
        end_time: moment(data.end_time, "hh:mm a").format("HH:mm:ss"),
        facility_name: data.facility_name,
        facility_id: data.facility_id,
        date: this.date,
        increment: this.increment,
        venue_service_id: this.venueService.venue_service_id,
        service: this.venueService.name,
        id: data.id != null ? data.id : 0,
        order_id: data.order_id,
        per_capacity: data.per_capacity,
        min_booking_time: data.min_booking_time,
        bookingCount: data.bookings != null ? data.bookings : 0,
      };
    },
    getFacilitySchedule() {
      if (this.participant && this.participant.showParticipants) {
        this.refresh = !this.refresh;
      }
      this.showLoader("Loading");
      this.$store
          .dispatch("loadFacilitySchedule", {
            venue_service_id: this.venueService.venue_service_id,
            date: this.date,
            game_formation_id: this.gameFormationFilter,
            backfill: this.checkBackfillPermission(
                this.$modules.facility.schedule.slug
            ),
          })
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              this.increment =
                  data.configuration.time_increment != null
                      ? data.configuration.time_increment
                      : 60;
              this.minBookingTime =
                  data.configuration.min_booking_time != null
                      ? data.configuration.min_booking_time
                      : 60;
              this.height = (60 / this.increment) * 1200;
              this.perCapacity = data.configuration.per_capacity
                  ? data.configuration.per_capacity
                  : 0;
              this.facilities = data.facilities;
              this.hideLoader();
              if (this.date != this.currentDate) {
                this.currentDate = moment().format("YYYY-MM-DD");
                //this.scrollToAvailable();
              }
              //this.scrollToAvailable();
            }
            this.generateWeekDays();
            this.selectCurrentDay()
          });
    },
    toDay() {
      this.date = moment().format("YYYY-MM-DD");
      this.getFacilitySchedule();
    },
    nextDate() {
      this.date = moment(this.date).add(1, "days").format("YYYY-MM-DD");
      this.getFacilitySchedule();
    },
    prevDate() {
      this.date = moment(this.date).subtract(1, "days").format("YYYY-MM-DD");
      this.getFacilitySchedule();
    },
    getSlotData(index) {
      return this.$store.getters.getFacilitySchedule[index];
    },
    openOrderCloseBooking(orderId) {
      this.bookingForm.showBookingForm = false;
      this.orderId = orderId;
    },
    completeOrder(orderId) {
      this.getFacilitySchedule();
      if (orderId) {
        if (this.bookingForm.per_capacity) {
          this.bookingForm.showBookingForm = false;
          this.openParticipants(this.bookingForm);
        } else {
          this.openOrderCloseBooking(orderId);
        }
      } else {
        this.bookingForm.showBookingForm = false;
      }
      // if (orderId) {
      //   this.bookingForm.showBookingForm = false;
      //   if (openParticipantModal && data !== null) {
      //     this.openParticipants(data);
      //   } else {
      //     this.openOrderCloseBooking(orderId);
      //   }
      // } else {
      //   this.bookingForm.showBookingForm = false;
      // }
    },
    showReceipt(id) {
      this.bookingOrderId = null;
      this.orderId = id;
    },
    repeatBookingReceipt(orderIds) {
      this.bookingOrderId = null;
      this.bookingForm.showBookingForm = false;
      this.orderIds = orderIds;
    },
    showReschedule(id) {
      this.bookingOrderId = null;
      this.rescheduleId = id;
    },
    showRefund(id) {
      this.$store.dispatch("loadOrderDetails", id).then((response) => {
        if (response.status == 200) {
          this.refund_dialog = true;
        }
      });
    },
    openParticipants(data) {
      this.participant = {
        showParticipants: true,
        start_time: moment(data.start_time, "hh:mm a").format("HH:mm:ss"),
        end_time: moment(data.end_time, "hh:mm a").format("HH:mm:ss"),
        facility_id: parseInt(data.facility_id),
        date: this.date,
      };
    },
    gotoCalendar() {
      this.$router.push({
        name: "CalendarWithParams",
        params: {
          data: btoa(
              JSON.stringify({
                venue_service: this.venueService,
                date: this.date,
              })
          ),
        },
      });
    },
    toggle() {
      setTimeout(() => {
        this.getFacilitySchedule();
      });
    },
    scrollToTop() {
      setTimeout(() => {
        const el = this.$refs.schedule;
        el.scrollTop = 0;
      });
    },

    scrollToAvailable() {
      this.scrollToTop();
      setTimeout(() => {
        const el = this.$refs.schedule;
        let available = this.$el.getElementsByClassName("available")[0];
        if (typeof available !== "undefined") {
          if (available.offsetTop) {
            el.scrollTop = available.offsetTop - 20;
          } else {
            el.scrollTop = available.getBoundingClientRect().top - 500;
          }
        }
      });
    },
    confirmCancel() {
      this.confirmModel = {
        id: this.order_id,
        title: `Do you want cancel this booking?`,
        description: `This will cancel current booking. By clicking <b>Yes</b> you can confirm cancel operation`,
        type: "cancel",
      };
    },
    deleteBookings(id) {
      this.showLoader('Wait');
      this.$http
          .delete(`venues/orders/${id}`)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status == true) {
              this.showSuccess("Booking cancelled successfully");
              this.getFacilitySchedule();
              this.bookingOrderId = null;
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    cancelRepeatBooking() {
      this.getFacilitySchedule();
      this.bookingOrderId = null;
    },
    repeatRefundAndCancel(amount, formData) {
      if (amount) {
        this.payments = [
          {
            card_type_id: null,
            payment_code: null,
            payment_method_id: null,
            amount: null,
            payment_method: null,
          },
        ];

        this.$store.commit("setOrderPayments", this.payments);
        this.repeatRefundAmount = amount;
        this.repeatBookingdata = formData;
        this.refund_dialog = true;
      }
    },
    reportDownload() {
      let date = moment(this.date).format("YYYY-MM-DD");
      let url =
          "?venue_service_id=" +
          this.venueService.venue_service_id +
          "&date=" +
          date;
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
          .get(`venues/facilities/bookings/golf/bookings-schedule${url}`, {
            responseType: "blob",
          })
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.downloadFile(response, "Tee Sheet");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    onScroll() {
      const refElement = this.$refs.schedule;
      if (refElement) {
        const scrollLeft = refElement.scrollLeft;
        const scrollTop = refElement.scrollTop;
        this.$refs.scheduleTimer.scrollTop = scrollTop;
        this.$refs.scheduleHeader.scrollLeft = scrollLeft;
      }
    },
    openCustomerProfile(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    getMainBoxWidth: function () {
      let width = this.$refs.main_box.clientWidth;
      return width;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-scroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.date-active{
 background-color: #062b48 !important;
  color: white !important;
}

.full-width-toggle .v-btn {
  flex-grow: 1;
  max-width: unset;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn{
  border: 1px solid rgba(105, 104, 104, 0.25) !important;
}

.block-dark-button {
  width: 12rem !important;
}


</style>
