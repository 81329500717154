<template>
<!--  <draggable v-model="slots" @start="dragStart" :group="{ name: 'people', pull: 'clone', put: true }" @end="dragStop" :data-status="getClassStatusName(status)">-->
    <v-hover v-slot="{ hover }">
      <v-sheet
        v-bind="{...$attrs}"
        block
        :height="height"
        width="200"
        :elevation="hover && status != 'not_available' ? 12 : 0"
        :class="
          `time-slot d-flex justify-center align-center border p-relative cursorstyle ${getClassStatusName(
            status
          )} ${
            hover && status != 'not_available' ? 'hoverstyle' : ''
          }   ${isPast} ${className}`
        "
        @mouseover="highlight"
        @mouseout="removeHighlight"
        :style="{ backgroundColor: bgColor }"
      >
        <div
          v-if="
            status == 'maintenance' || status == 'event' || status == 'dependency'
          "
        >
          <span v-html="name"></span>
        </div>

        <v-row v-else-if="status != 'not_available'" class="pa-0 ma-0">
          <v-tooltip bottom v-if="bgColorName">
            <template v-slot:activator="{ on, attrs }">
              <v-col
                v-bind="attrs"
                v-on="on"
                md="12"
                sm="12"
                v-if="
                  bookings < capacity && !isPast && !userParticipant.customer_id
                "
                class="pa-0"
              >
                <v-btn color="teal" dark fab x-small @click="onClick('add')">
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              <v-col v-else>
                <span v-bind="attrs" v-on="on" md="12" sm="12"></span>
              </v-col>
            </template>
            <span>{{ bgColorName }}</span>
          </v-tooltip>

          <v-col
            md="12"
            sm="12"
            v-else-if="
              bookings < capacity && !isPast && !userParticipant.customer_id
            "
            class="pa-0"
          >
            <v-btn color="teal" dark fab x-small @click="onClick('add')">
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
          </v-col>

          <v-col md="4" sm="6" v-if="bookings > capacity && isPast" class="pa-0">
          </v-col>

          <v-col
            :md="bookings < capacity && !isPast ? 12 : 12"
            :sm="bookings < capacity ? 12 : 12"
            class="pa-0"
            v-if="userParticipant.status_id"
            @click="onClick('participant')"
            :class="getClassStatusName(status)"
          >
            <div class="credit-owed" v-if="userParticipant.credit_owed">
              <span>C</span>
            </div>
            <div class="valid-member" v-if="userParticipant.member_id">
              <span>M</span>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="white--text pa-0" v-bind="attrs" v-on="on">
                  <span class="d-block">{{
                    userParticipant.is_trainer == 1
                      ? name
                      : userParticipant.customer_name
                  }}</span>
                  <span
                    class="d-block"
                    v-if="
                      userParticipant.is_trainer === 0 &&
                        userParticipant.i_paid === 1
                    "
                    >{{ userParticipant.order_total | toCurrency }}</span
                  >
                </div>
              </template>
              <span>{{ products }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-else-if="status == 'not_available'" class="pa-0">
          <v-col md="12" class="text-center text-white">
            <span>{{ name }}</span>
          </v-col>
        </v-row>
      </v-sheet>

    </v-hover>
<!--  </draggable>-->
</template>

<script>
// import draggable from "vuedraggable";

export default {
  // components: {draggable},
  props: {
    className:{type:String, default:''},
    id: { type: Number },
    status: { type: String, default: "available" },
    draggable: { type: Boolean, default: false },
    height: { type: Number, default: 50 },
    index: { type: Number, default: 0 },
    bookings: { type: Number, default: 0 },
    capacity: { type: Number, default: 0 },
    capacityIndex: { type: Number, default: 0 },
    name: { type: String, default: "" },
    isPast: { type: Boolean, default: false },
    creditOwed: { type: Number, default: 0 },
    isDuplicate: { type: Number, default: null },
    products: { type: String, default: "N/A" },
    bgColor: { type: String, default: null },
    bgColorName: { type: String, default: null },
    userParticipant: { type: Object, default: () => ({}) },
  },
data(){
    return {
      // slots:[],
      // drag:false,
    }
},
  methods: {
    // dragStart(e){
    //   if(this.checkIsAvailable(e.from.dataset)){
    //     console.log('Cannot reschedule if no booking');
    //     return;
    //   }
    //   this.drag = true
    // },
    // dragStop(e){
    //   console.log('e.from');
    //   console.log(e.from);
    //   console.log('e.to',e.to.dataset);
    //   console.log(typeof e.to);
    //   this.drag = false
    //
    //   if(!this.checkIsAvailable(e.to.dataset)){
    //     console.log('not available');
    //     return;
    //   }
    //   let data= {
    //     from :e.from.dataset,
    //     to: e.to.dataset
    //   };
    //
    //   this.rescheduleBooking(data);
    // },
    // rescheduleBooking(data){
    //   this.$emit('rescheduleBooking',data);
    //   // this.showLoader('Trying to reschedule...');
    //   // let data = { from: fromData, to: toData };
    //   // this.$http
    //   //     .post(`venues/facilities/bookings/golf/get-reschedule-data`, data)
    //   //     .then((res) => {
    //   //       if (res.status == 200 && res.data.status == true) {
    //   //         this.hideLoader();
    //   //         this.showSuccess("Loaded reschedule data");
    //   //       }
    //   //     })
    //   //     .catch((error) => {
    //   //       this.hideLoader();
    //   //       this.errorChecker(error);
    //   //     });
    // },
    // checkIsAvailable(item){
    //   return item.status === 'available';
    // },
    getClassStatusName(status) {
      if (this.userParticipant.status_id) {
        if (this.userParticipant.is_trainer == 1) {
          return "trainer";
        } else if (this.userParticipant.status_id == 1) {
          if (this.isDuplicate) {
            return "color_purple";
          }
          return "paid";
        } else {
          if (this.isDuplicate) {
            return "color_purple";
          }
          return "unpaid";
        }
      } else {
        if (
          (status == "unpaid" || status == "paid") &&
          !this.userParticipant.status_id
        ) {
          if (!this.isPast) {
            return "available";
          } else {
            return "completed";
          }
        } else {
          if (status == "trainer") {
            return "available";
          } else {
            return status;
          }
        }
      }
    },
    onClick(type) {
      if (this.status != "not_available" && this.status != "maintenance") {
        if (type == "add") {
          this.$emit("click");
        } else {
          this.$emit("participants");
        }
      }
    },
    highlight() {
      this.$store.dispatch("highlightTime", this.index);
    },
    removeHighlight() {
      this.$store.dispatch("removeHighlightTime", this.index);
    },
  },
};
</script>

<style scoped>
.v-sheet.border {
  border: 1px solid #fff;
}
.booked {
  background: rgb(0, 89, 118) !important;
  color: #fff;
  cursor: pointer;
}
.available {
  cursor: pointer;
}
.not_available {
  cursor: not-allowed;
  background: rgb(176, 191, 196) !important;
}
.hoverstyle {
  border: 2px solid teal;
}
.v-btn {
  margin: 6px 8px;
  width: 20px;
  height: 20px;
}
.maintenance {
  background: rgb(150, 26, 4) !important;
  color: #fff;
  cursor: not-allowed;
}
.trainer {
  background: rgb(21, 114, 2) !important;
  color: #fff;
  cursor: pointer;
}
.completed {
  background: #e0f4f4;
}

.workshop {
  color: rgb(7, 0, 0);
  cursor: pointer;
}
.event {
  background: #91a0b4 !important;
  cursor: not-allowed !important ;
  border: 0 solid #e0f4f4;
  color: #e0f1f4;
}
.dependency {
  background: #a79493 !important;
  cursor: not-allowed !important ;
  border: 0 solid #e0f4f4;
  color: #fcf0f0;
}
.unpaid {
  background: rgb(206, 168, 0) !important;
  color: #fff;
  cursor: pointer;
}
.paid {
  background: rgb(0, 89, 118) !important;
  color: #fff;
  cursor: pointer;
}

.color_purple {
  background: #c025e0 !important;
  color: #fff;
  cursor: pointer;
}
.credit-owed {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 18px;
  background: #ffc000;
  color: #fff;
  border-radius: 50%;
  height: 18px;
  z-index: 0;
}
.valid-member {
  position: absolute;
  top: 2px;
  right: 0px;
  left: 2px;
  width: 18px;
  background-color: #009688;
  color: #fff;
  border-radius: 50%;
  height: 18px;
  z-index: 0;
}
.p-relative {
  position: relative;
}
</style>
