<template>
  <v-sheet
    color="#2c3b46"
    dark
    :width="getHeaderCellWidth(capacity)"
    height="55px"
    class="title flex-grow-0 flex-shrink-0 text-center pb-1 pt-3 r-border-white"
  >
    <v-tooltip bottom v-if="tooltip">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          {{ name }}
        </div>
      </template>
      {{ tooltip }}
    </v-tooltip>
    <div v-else class="facility-name_header" :title="name">
      {{ name }}
    </div>
    <div class="caption mb-2" v-if="formation.length">
      ({{ gameFormations() }})
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: {
    name: { type: String, default: "Pitch Name" },
    formation: { type: Array, default: () => [] },
    tooltip: { type: String },
    capacity: { type: Number, default: 1 },
    mainBoxWidth: { type: Number, default: 200 },
  },
  methods: {
    gameFormations() {
      return this.formation.map((item) => item.name).join(",");
    },
    getHeaderCellWidth(capacity) {
      if (200 * capacity > this.mainBoxWidth) {
        return this.mainBoxWidth;
      } else {
        return 200 * capacity;
      }
    },
  },
};
</script>

<style>
.r-border-white {
  border-right: 1px solid #fff !important;
}
</style>