<template>
  <v-card
    color="grey"
    :height="height"
    :width="getWidth(this.capacity)"
    tile
    class="flex-grow-0 flex-shrink-0"
  >
    <template v-for="(slot, i) in bookings">
      <v-card class="d-flex flex-row" :key="`${i}_${slot.index}`">
        <capacity-slot
          :data-start-time="slot.start_time"
          :data-end-time="slot.end_time"
          :data-facility-id="id"
          :data-date="date"
          v-for="c in capacity"
          :key="`${c}_${slot.index}`"
          :height="slot.height"
          :class-name="`pa-2 text-center ${slot.index}`"
          :status="slot.status"
          :bookings="slot.bookings"
          :products="getSingleUserParticipantProducts(slot.participants, c)"
          :isPast="slot.isPast"
          :name="slot.name"
          :isDuplicate="getSingleUserDuplicate(slot.participants, c)"
          :userParticipant="getSingleUserParticipant(slot.participants, c)"
          :creditOwed="slot.credit_owed"
          :index="slot.index"
          :capacity="capacity"
          :capacityIndex="c"
          :bgColor="slot.bgColor"
          :bgColorName="slot.bgColorName"
          @click="onClickSlot(slot)"
          @rescheduleBooking="rescheduleBooking"
          @participants="getParticipants(slot)"
        ></capacity-slot>
      </v-card>
      <v-divider :key="`d_${slot.index}`"></v-divider>
    </template>
  </v-card>
</template>

<script>
import CapacitySlot from "./GolfCapacitySlot";
export default {
  props: {
    bookings: { type: Array, default: () => [] },
    increment: { type: Number, default: 60 },
    height: { type: Number, default: 1000 },
    name: { type: String },
    id: { type: Number },
    date: { type: String },
    perCapacity: { type: Number, default: 1 },
    minBookingTime: { type: Number, default: 60 },
    capacity: { type: Number, default: 0 },
  },
  components: {
    CapacitySlot
  },
  data() {
    return {};
  },
  methods: {
    rescheduleBooking(data){
      this.$emit('rescheduleBooking',data);
      },
    onClickSlot(data) {
      let slotData = data;
      if (this.perCapacity == 1) {
        delete slotData.order_id;
        delete slotData.id;
      }
      slotData.capacity = this.capacity;
      slotData.facility_name = this.name;
      slotData.facility_id = this.id;
      slotData.per_capacity = this.perCapacity;
      slotData.min_booking_time = this.minBookingTime;

      this.$emit("open-booking-form", slotData);
    },
    getParticipants(data) {
      let slotData = data;
      slotData.capacity = this.capacity;
      slotData.facility_name = this.name;
      slotData.facility_id = this.id;
      slotData.per_capacity = this.perCapacity;
      slotData.min_booking_time = this.minBookingTime;
      this.$emit("open-participans-model", slotData);
    },
    getSingleUserParticipant(participants, c) {
      if (typeof participants != "undefined") {
        if (participants[c - 1] !== undefined) {
          return participants[c - 1];
        }
      }
      return {};
    },
    getSingleUserDuplicate(participants, c) {
      if (typeof participants != "undefined") {
        if (participants[c - 1] !== undefined) {
          return participants[c - 1].fb_duplicate_id;
        }
      }
      return null;
    },
    getSingleUserParticipantProducts(participants, c) {
      if (typeof participants != "undefined") {
        if (participants[c - 1] !== undefined) {
          return participants[c - 1].products;
        }
      }
      return "";
    },
    getWidth(capacity) {
      return capacity > 0 ? capacity * 200 : 200;
    },
  },
};
</script>

<style></style>
