var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',_vm._b({class:("time-slot d-flex justify-center align-center border p-relative cursorstyle " + (_vm.getClassStatusName(
            _vm.status
          )) + " " + (hover && _vm.status != 'not_available' ? 'hoverstyle' : '') + "   " + _vm.isPast + " " + _vm.className),style:({ backgroundColor: _vm.bgColor }),attrs:{"block":"","height":_vm.height,"width":"200","elevation":hover && _vm.status != 'not_available' ? 12 : 0},on:{"mouseover":_vm.highlight,"mouseout":_vm.removeHighlight}},'v-sheet',Object.assign({}, _vm.$attrs),false),[(
            _vm.status == 'maintenance' || _vm.status == 'event' || _vm.status == 'dependency'
          )?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.name)}})]):(_vm.status != 'not_available')?_c('v-row',{staticClass:"pa-0 ma-0"},[(_vm.bgColorName)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(
                  _vm.bookings < _vm.capacity && !_vm.isPast && !_vm.userParticipant.customer_id
                )?_c('v-col',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"md":"12","sm":"12"}},'v-col',attrs,false),on),[_c('v-btn',{attrs:{"color":"teal","dark":"","fab":"","x-small":""},on:{"click":function($event){return _vm.onClick('add')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1):_c('v-col',[_c('span',_vm._g(_vm._b({attrs:{"md":"12","sm":"12"}},'span',attrs,false),on))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.bgColorName))])]):(
              _vm.bookings < _vm.capacity && !_vm.isPast && !_vm.userParticipant.customer_id
            )?_c('v-col',{staticClass:"pa-0",attrs:{"md":"12","sm":"12"}},[_c('v-btn',{attrs:{"color":"teal","dark":"","fab":"","x-small":""},on:{"click":function($event){return _vm.onClick('add')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1):_vm._e(),(_vm.bookings > _vm.capacity && _vm.isPast)?_c('v-col',{staticClass:"pa-0",attrs:{"md":"4","sm":"6"}}):_vm._e(),(_vm.userParticipant.status_id)?_c('v-col',{staticClass:"pa-0",class:_vm.getClassStatusName(_vm.status),attrs:{"md":_vm.bookings < _vm.capacity && !_vm.isPast ? 12 : 12,"sm":_vm.bookings < _vm.capacity ? 12 : 12},on:{"click":function($event){return _vm.onClick('participant')}}},[(_vm.userParticipant.credit_owed)?_c('div',{staticClass:"credit-owed"},[_c('span',[_vm._v("C")])]):_vm._e(),(_vm.userParticipant.member_id)?_c('div',{staticClass:"valid-member"},[_c('span',[_vm._v("M")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"white--text pa-0"},'div',attrs,false),on),[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.userParticipant.is_trainer == 1 ? _vm.name : _vm.userParticipant.customer_name))]),(
                      _vm.userParticipant.is_trainer === 0 &&
                        _vm.userParticipant.i_paid === 1
                    )?_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.userParticipant.order_total)))]):_vm._e()])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.products))])])],1):_vm._e()],1):(_vm.status == 'not_available')?_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"text-center text-white",attrs:{"md":"12"}},[_c('span',[_vm._v(_vm._s(_vm.name))])])],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }